import React, { FC, ReactElement } from 'react';

import { Container } from 'layout';
import SecondaryNavigationItem from 'components/SecondaryNavigationItem';

import { isEmptyArray } from 'utils/array';

import './SecondaryNavigation.scss';
import './ShadowSecondaryNavigation.scss';
import { IPropsSecondaryNavigation } from './models';

const SecondaryNavigation: FC<IPropsSecondaryNavigation> = ({
  secondaryNavigation,
  tags,
}): ReactElement | null => {
  if (isEmptyArray(secondaryNavigation)) return null;
  const {
    properties: { title, alpSecondaryNavigationTags },
  } = secondaryNavigation[0];

  return (
    <div className="secondary-navigation" data-test="SecondaryNavigation">
      <Container>
        <div className="secondary-navigation__title">{title}</div>
        <div className="secondary-navigation__tags-list">
          {alpSecondaryNavigationTags
            .sort(
              (a, b) => Number(a.properties.articleTagOrder) - Number(b.properties.articleTagOrder)
            )
            .map((tag) => (
              <SecondaryNavigationItem
                tag={tag.properties.tagsContent[0]}
                activeTag={tags[0].title}
                key={tag.properties.tagsContent[0].title}
              />
            ))}
        </div>
      </Container>
    </div>
  );
};

export default SecondaryNavigation;
