import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

import { isSameString } from 'utils/stringUtils/stringUtils';

const SecondaryNavigationItem = ({ tag, activeTag }) => (
  <Link
    to={tag.url[0].url}
    className={classNames('secondary-navigation__tag-item', {
      active: isSameString(tag.title, activeTag),
    })}
  >
    {tag.title}
  </Link>
);

export default SecondaryNavigationItem;
