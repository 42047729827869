import React, { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import RelatedArticleCard from 'components/RelatedArticleCard';
import Button from 'common/Button';

import useScreenRecognition from 'hooks/useScreenRecognition';
import { isEmptyArray } from 'utils/array';

import './ArticleCardsList.scss';
import { LimitMobile, LimitDesktop } from './constants';
import { IPropsArticleCards } from './models';

const ArticleCardsList: FC<IPropsArticleCards> = ({
  cards,
  loadMoreArticleCardsText,
  loadMoreArticleCardsTextAriaLabel,
  showMoreButtonAriaLabel,
}): ReactElement | null => {
  if (isEmptyArray(cards)) return null;
  const { isMobile } = useScreenRecognition();

  const perPage = useMemo(() => (isMobile ? LimitMobile : LimitDesktop), [isMobile]);

  const [cardsOnPageCount, setCardsOnPageCount] = useState(0);

  useEffect(() => {
    if (isMobile === null) return;
    setCardsOnPageCount(perPage);
  }, [perPage, isMobile]);

  const cardsList = useMemo(
    () => cards.slice(0, cardsOnPageCount),
    [cardsOnPageCount, cards, isMobile]
  );

  const loadMoreArticleCardsHandler = useCallback(
    () => setCardsOnPageCount((prevState) => prevState + perPage),
    []
  );

  return (
    <div className="article-cards-list" data-test="ArticleCardsList">
      <div className="article-cards-list__wrapper">
        {cardsList.map((article) => (
          <RelatedArticleCard
            key={article.id}
            articleIntroduction={article.articleHeroBanner[0].properties.articleIntroduction}
            articleShortTitle={article.articleHeroBanner[0].properties.articleShortTitle}
            mainArticleImage={article.articleHeroBanner[0].properties.image}
            mobileArticleImage={article.articleHeroBanner[0].properties.mobileImage}
            mainArticleImageAltText={article.articleHeroBanner[0].properties.heroBannerImageAltText}
            link={article.link}
            tags={article.tags}
            showMoreButtonAriaLabel={showMoreButtonAriaLabel}
          />
        ))}
      </div>
      <div className="article-cards-list__load-more-wrapper">
        {cardsList.length !== cards.length ? (
          <Button
            data-test="LoadMoreArticleCards"
            ariaLabel={loadMoreArticleCardsTextAriaLabel}
            clickHandler={loadMoreArticleCardsHandler}
            className="article-cards-list__load-more-button"
          >
            {loadMoreArticleCardsText}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default ArticleCardsList;
